<template>
  <v-row class="pa-3 pr-5" fill-height style="height: 92vh">
    <v-dialog v-model="dialogFavorite" v-if="dialogFavorite" max-width="350px">
      <v-card>
        <v-card-title class="text-h5 primary--text">
          {{ $t('aggiungi-preferito') }} </v-card-title>

        <v-card-text>
          <v-autocomplete :placeholder="$t('seleziona-un-contenitore')" :items="iContainers" autocomplete
            v-model="choosenContainerDialog" return-object item-text="nome">

            <template v-slot:item="{ item, }">
              {{ item.desc[config.userLang] }}
            </template>

          </v-autocomplete>

          {{ choosenContainerDialog?.descrizione }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="
            saveFavorite();
          dialogFavorite = false;">
            {{ $t('aggiungi') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Main row -->
    <v-col cols="6">
      <!-- Left col -->
      <v-row style="height: 90vh">
        <!-- Row in left col -->
        <v-col cols="12">
          <v-card height="100%" elevation="5">
            <v-card-title class="text-uppercase primary--text">
              {{ $t('ricerche-salvate') }} </v-card-title>
            <v-card-text class="pa-3 pt-0">
              <v-simple-table dense fixed-header scrollable height="28vh">
                <!-- Table saved searches -->
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="25%" class="text-left secondary--text">
                        {{ $t('data-creazione') }} </th>
                      <th width="45%" class="text-left secondary--text">
                        {{ $t('descrizione') }} </th>
                      <th class="text-left secondary--text">{{ $t('contenitore') }}</th>
                      <th class="text-left secondary--text"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="search in searches" :key="search.idSearch" class="text-caption">
                      <td>{{ search.dataCreazione }}</td>
                      <td>{{ search.nomeRicerca }}</td>
                      <td>{{ search.nome }}</td>

                      <td class="text-right">
                        <v-icon @click="openContainerAutofilters(search)" color="blue">mdi-magnify</v-icon>
                        <v-icon @click="deleteSearch(search)" color="red">mdi-trash-can</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card height="100%" elevation="5">
            <v-card-title class="text-uppercase primary--text">
              {{ $t('preferiti') }} <v-spacer />
              <v-btn elevation="2" fab x-small color="success"
                @click="
                  choosenContainerDialog = {};
                dialogFavorite = true;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-3 pt-0">
              <v-simple-table dense fixed-header scrollable height="28vh">
                <!-- Table Favorites -->
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="70%" class="text-left secondary--text">
                        {{ $t('descrizione') }} </th>
                      <th class="text-left secondary--text">{{ $t('contenitore') }}</th>
                      <th class="text-left secondary--text"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="favorite in favorites" :key="favorite.nome" class="text-caption">
                      <td>{{ favorite.descrizione }}</td>
                      <td>{{ favorite.nome }}</td>

                      <td class="text-right">
                        <v-icon @click="openContainer(favorite)" color="blue">
                          mdi-magnify</v-icon>
                        <v-icon @click="deleteFavorite(favorite)" color="red">mdi-trash-can</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <!-- Right col -->
      <v-card height="100%" elevation="5">
        <v-card-title class="text-uppercase primary--text" :loading="loadingElabs">
          {{ $t('richieste-elaborazione') }} <v-spacer />
          <v-btn elevation="2" fab x-small color="success" :loading="loadingRequests" @click="getRequests()">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-3 pt-0">
          <v-simple-table dense fixed-header scrollable height="79vh">
            <!-- Table requests -->
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th class="text-left secondary--text" width="25%">
                    {{ $t('data-creazione') }} </th>
                  <th class="text-left secondary--text">{{ $t('descrizione') }}</th>
                  <th class="text-left secondary--text">{{ $t('contenitore') }}</th>
                  <th class="text-left secondary--text">{{ $t('password') }}</th>
                  <th class="text-left secondary--text">{{ $t('num-file') }}</th>
                  <th class="text-left secondary--text">{{ $t('tipo-elaborazione') }}</th>
                  <th class="text-left secondary--text"></th>
                  <th class="text-left secondary--text"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="request in requests" :key="request._id" class="text-caption">
                  <td>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small :color="getStatusColor(request.stato)" @click="requestsBtnHandler(request)"
                          v-bind="attrs" v-on="on" :disabled="loadingElabs">
                          <v-icon color="white">
                            {{ getStatusIcon(request.stato) }}
                          </v-icon>

                          <!-- If request is in progress, show % -->
                          <!-- <v-progress-circular
                            v-else
                            :value="request.avanzamento"
                            color="white"
                          /> -->
                        </v-btn>
                      </template>
                      <span>{{ getStatusText(request.stato) }}</span>
                    </v-tooltip>
                  </td>

                  <td>{{ request.dataCreazione }}</td>
                  <td>{{ request.descrizione }}</td>
                  <td>{{ request.contenitore }}</td>
                  <td>{{ request.password }}</td>
                  <td>{{ request.numDoc }}</td>
                  <td>{{ request.descrizioneTipo }}</td>

                  <td class="text-right">
                    <v-icon v-if="request.stato !== '2'" @click="copyRequest(request)" color="light-blue">mdi-content-copy
                    </v-icon>
                  </td>
                  <td class="text-right">
                    <v-icon v-if="request.stato !== '2'" @click="deleteRequest(request)" color="red">mdi-trash-can
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import mixins from "./mixins.js";
//import config from "../../public/static/js/config"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dashboard',
  mixins: [mixins],
  props: ["iContainers"],
  mounted: function () {
    let _ = this;
    _.getFavorites();
    _.getSearches();
    _.getRequests();
    setInterval(function () {
      _.getRequests();
    }, 60000);
  },

  data() {
    return {
      // eslint-disable-next-line no-undef
      config: config,
      searches: [],
      favorites: [],
      requests: [],
      loadingElabs: false,
      loadingSearches: false,
      loadingFavorites: false,
      loadingRequests: false,

      dialogFavorite: false,
    };
  },

  methods: {
    getStatusColor(statusCode) {
      switch (statusCode) {
        case "0":
          return "grey";
        case "1":
          return "grey";
        case "2":
          return "green";
        case "3":
          return "green";
        default:
          return "red";
      }
    },

    getStatusIcon(statusCode) {
      switch (statusCode) {
        case "0":
          return "mdi-play";
        case "1":
          return "mdi-pause";
        case "2":
          return "play-speed";
        case "3":
          return "mdi-download";
        default:
          return "mdi-alert";
      }
    },

    getStatusText(statusCode) {
      switch (statusCode) {
        case "0":
          return this.$t('elaborazione-salvata');
        case "1":
          return this.$t('elaborazione-in-coda');
        case "2":
          return this.$t('elaborazione-in-corso');
        case "3":
          return this.$t('elaborazione-completata');
        default:
          return this.$t('elaborazione-in-errore');
      }
    },
    requestsBtnHandler(row) {
      let _ = this;
      if (row.stato === "0") {
        let payload = {
          idElab: row.idElab,
          codiceStato: "1",
        };

        _.$http
          // eslint-disable-next-line no-undef
          .post(config.cmUrl + "/user/prefs/update/elab", payload)
          .then(() => {
            _.Notify(_.$i18n.t("message.dashboard.elabAccodataSucc"), 0);
            _.getRequests();
          })
          .catch((err) => {
            if ([0, 401, 403].includes(err.status)) {
              _.Notify(_.$i18n.t("message.dashboard.elabAccodataErr"), 1);
              console.error(err);
            }
          });
      } else if (row.stato === "1") {
        let payload = {
          idElab: row.idElab,
          codiceStato: "0",
        };

        _.$http
          // eslint-disable-next-line no-undef
          .post(config.cmUrl + "/user/prefs/update/elab", payload)
          .then(() => {
            _.Notify(_.$i18n.t("message.dashboard.elabAccodataSucc"), 0);
            _.getRequests();
          })
          .catch((err) => {
            if ([0, 401, 403].includes(err.status)) {
              _.Notify(_.$i18n.t("message.dashboard.elabAccodataErr"), 1);
              console.error(err);
            }
          });
      } else if (row.stato === "2") {
        _.Notify(_.$i18n.t("message.dashboard.elabWaitSucc"), 2);
      } else if (row.stato === "3") {
        _.loadingElabs = true;
        _.$http
          // eslint-disable-next-line no-undef
          .post(config.cmUrl + "/docs/elabs", { idElab: row.idElab })
          .then((urlResp) => {
            if (urlResp.data.data && urlResp.data.data.esito) {
              _.Notify(_.$i18n.t("message.dashboard.elabAccodataSucc"), 0);
              _.downloadFile(
                urlResp.data.data.url,
                row.nomeFile.split("/").pop()
              );
            } else {
              _.Notify(_.$i18n.t("message.dashboard.elabAccodataErr"), 1);
            }
            _.loadingElabs = false;
          })
          .catch((err) => {
            if ([0, 401, 403].includes(err.status)) {
              _.Notify(_.$i18n.t("message.dashboard.elabAccodataErr"), 1);
              console.error(err);
            }
            _.loadingElabs = false;
          });
      } else if (row.stato === "4") {
        _.Notify(_.$i18n.t("message.dashboard.elabErrored"), 1);
      }
    },
    downloadFile(url, fileName) {
      window.open(url, fileName);
      // let fileElement = document.createElement("fileElement"); // Create tmp obj
      // document.body.appendChild(fileElement); // Add obj to page
      // fileElement.style = "display: none"; // Make obj invisible
      // fileElement.href = url; // Set url to open
      // fileElement.download = fileName; // Mandatory attribute for download object
      // fileElement.click(); // Emulate user click
      // window.URL.revokeObjectURL(url); // Clear element and DOM
    },
    getSearches() {
      let _ = this;
      _.searches = [];
      _.loadingSearches = true;
      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/user/prefs/read/searches", {})
        .then((result) => {
          for (var e of result.body.data) {
            e.dataCreazione = _.fixDate(e.dataCreazione);
          }
          _.searches = result.body.data;
          _.loadingSearches = false;
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            _.Notify(_.$i18n.t("message.favorite.failGet"), 1);
            console.error(err);
          }
          _.loadingSearches = false;
        });
    },
    deleteSearch(search) {
      let _ = this;
      let payload = {
        idSearch: search.idSearch,
      };
      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/user/prefs/delete/searches", payload)
        .then(() => {
          _.getSearches();
          _.Notify(_.$i18n.t("message.reseach.delete"), 0);
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            _.Notify(_.$i18n.t("message.reseach.faildelete"), 1);
            console.error(err);
          }
        });
    },

    openContainerAutofilters(filter) {
      let _ = this;
      _.$emit("openContainerAutofilters", filter);
    },
    getRequests() {
      let _ = this;
      _.requests = [];
      _.loadingRequests = true;
      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/user/prefs/read/elab", {})
        .then((result) => {
          for (var e of result.body.data) {
            e.dataCreazione = _.fixDate(e.dataCreazione);
          }
          _.requests = result.body.data;
          _.loadingRequests = false;
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            _.Notify(_.$i18n.t("message.elab.failGet"), 1);
            console.error(err);
          }
          _.loadingRequests = false;
        });
    },
    copyRequest(row) {
      let _ = this;
      var payload = {
        idElab: row.idElab,
      };
      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/user/prefs/copy/elab", payload)
        .then(() => {
          _.getRequests();
          _.Notify(_.$i18n.t("message.elab.copy"), 0);
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            _.Notify(_.$i18n.t("message.elab.failcopy"), 1);
            console.error(err);
          }
        });
    },
    deleteRequest(row) {
      let _ = this;
      var payload = {
        idElab: row.idElab,
      };

      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/user/prefs/delete/elab", payload)
        .then(() => {
          _.getRequests();

          _.Notify(_.$i18n.t("message.elab.delete"), 0);
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            _.Notify(_.$i18n.t("message.elab.faildelete"), 1);
            console.error(err);
          }
        });
    },

    /*
      FAVORITE METHODS
    */

    saveFavorite() {
      let _ = this;

      var payload = {
        nome: _.choosenContainerDialog.nome,
        descrizione: _.choosenContainerDialog.descrizione,
      };

      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/user/prefs/insert/favorites", payload)
        .then(() => {
          _.Notify(this.$t('preferito-aggiunto'), 0);

          _.getFavorites();
          _.dialogFavorite = false;
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            _.Notify(_.$i18n.t("message.favorite.error"), 1);
            console.error(err);
          }
        });
    },

    addFavorite() {
      let _ = this;

      _.dialogFavorite = true;
    },

    openContainer(container) {
      let _ = this;

      _.$emit("openContainer", container);
    },

    deleteFavorite(favorite) {
      let _ = this;

      var payload = {
        idPreferito: favorite.nome,
      };

      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/user/prefs/delete/favorites", payload)
        .then(() => {
          _.Notify(_.$i18n.t("message.favorite.delete"), 0);
          _.getFavorites();
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            _.Notify(_.$i18n.t("message.favorite.faildelete"), 1);
            console.error(err);
          }
        });
    },

    getFavorites() {
      let _ = this;

      _.favorites = [];
      _.loadingFavorites = true;

      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/user/prefs/read/favorites")
        .then((result) => {
          _.favorites = result.body.data;
          _.loadingFavorites = false;
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            _.Notify(_.$i18n.t("message.favorite.failGet"), 1);
            console.error(err);
          }
        });
    },

    /*
      UTILS
    */

    fixDate(date) {
      return new Date(date).toISOString().replace("T", " ").slice(0, 19);
    },
  },
};
</script>

<style scoped>
.favouriteBox {
  width: 140px;
  height: 140px;
  background-color: var(--v-accent-base);
  border-radius: 4px;
}
</style>