<template>
  <v-app>
    <v-container class="pa-0 ma-0" fill-height fluid v-if="loading">
      <v-row class="justify-center align-center">
        <v-progress-circular size="128" width="3" color="secondary" indeterminate>
          <img src="/static/img/logo.png" style="height: 64px; width: 64px" />
        </v-progress-circular>
      </v-row>
    </v-container>

    <HeaderApp v-if="!loading" style="max-height: 72px" />

    <v-main>
      <v-container class="pa-0 ma-0" fluid fill-height>
        <v-row v-if="!loading" style="height: 100%">
          <v-col style="max-width: 95px" class="pr-0 pb-0 mb-0 mt-0 pt-0">
            <NavLeft @goTo="goTo" />
          </v-col>
          <v-col class="mb-0 pb-0">
            <router-view @openContainer="openContainer" @openContainerAutofilters="openContainerAutofilters"
              @resetAll="resetAll" :iFilter="filter" :iContainer="container" :iContainers="containers">
            </router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HeaderApp from "@/components/HeaderApp";
import NavLeft from "@/components/NavLeft";
import mixins from "./mixins.js";
//import config from "../../public/static/js/config.js";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: { HeaderApp, NavLeft },
  mixins: [mixins],

  mounted: function () {
    this.getContainers();
  },

  data() {
    return {
      containers: [], // All containers for current user
      container: null, // Selected container from favorites
      filter: null, // Selected filter from saved filters
      // eslint-disable-next-line no-undef
      loading: config.loading,
    };
  },

  methods: {
    openContainer(container) {
      // Open container with no filters filled
      let _ = this;

      _.container = container;
      _.goTo('Tabella');
    },

    openContainerAutofilters(filter) {
      // Open container with some filters filled
      let _ = this;

      _.filter = filter;
      _.goTo('Tabella');
    },

    resetAll() {
      // Reset local variables
      let _ = this;

      _.filter = null;
      _.container = null;
    },

    goTo(route) {
      this.$router.push({ name: route }).catch((err) => err);
    },

    getContainers() {
      let _ = this;
      _.containers = [];
      _.loading = true;

      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/getAuthorizedContainers", {})
        .then((result) => {
          for (var container of result.body.data) {
            if (container && container.desc) {
              // eslint-disable-next-line no-undef
              container.descrizione = container.desc[config.userLang];
              container.levelAuth = _.getAuthLevel(container.authorizations);

              _.containers.push(container);
            }
          }
          _.loading = false;
          _.goTo('Dashboard');
        })
        .catch((err) => {
          _.loading = false;
          _.Notify(_.$i18n.t("getCont"), 1);
          console.error(err)
        });
    },

    getAuthLevel(auths) {
      // eslint-disable-next-line no-undef
      const authList = config.authList;
      let lvl = 0;

      for (const auth of auths) {
        if (authList[auth] && authList[auth] > lvl) {
          lvl = authList[auth];
        }
      }

      return lvl;
    },
  },
};
</script>