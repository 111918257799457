//import config from "../../public/static/js/config.js";

export default {
  methods: {
    Notify(text, type, showUnauth = false) {
      let _ = this

      // eslint-disable-next-line no-undef
      if (!showUnauth && !config.authSuccessful)
        return;

      let nofifyText = text;

      let nofifyType;

      switch (type) {
        case 0:
          nofifyType = 'success';
          break;
        case 2:
          nofifyType = 'info';
          break;
        default:
          nofifyType = 'error';
          break;
      }

      _.$notify({
        group: 'main',
        text: nofifyText,
        type: nofifyType,
      });
    }
  }
}
