<template>
  <v-app id="ContainerImage">
    <v-container fill-height class="justify-center">
      <v-card width="400px" class="pa-5" color="rgba(225, 225, 225, 0.75)" elevation="5">
        <v-row class="mt-8 justify-center">
          <img src="/static/img/logo.png" style="height: 64px; width: 64px" />
        </v-row>
        <v-row class="mt-8 mb-5 justify-center primary--text">
          <h3>{{ $t('login') }}</h3>
        </v-row>
        <!--<v-card-text v-if="loaderAuthList" style="justify-content: center;display: flex;">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="70"
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-else>-->
        <v-card-text >
          <v-select :loading="loaderAuthList" loader-height="3" solo v-model="authType" :label="$t('user-type')" :items="userTypeList" item-value="authType"
            item-text="description" />

          <v-text-field solo v-model="authUsername" type="text" :label="$t('username')" />
          <v-text-field solo v-model="authPassword" type="password" :label="$t('password')" />

          <v-row class="mt-2 mb-3 justify-center">
            <v-btn :disabled="loaderAuthList" large :loading="loading" @keyup.native.enter="checkLogin()" @click="checkLogin()" color="primary">
              {{ $t('accedi') }} </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import mixins from "./mixins.js";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  mixins: [mixins],

  mounted: function () {
    let _ = this

    let payload = {
      // eslint-disable-next-line no-undef
      portal: config.portal,
    }
    _.loaderAuthList = true
    _.$http
      // eslint-disable-next-line no-undef
      .post(config.cmUrl + "/listAuth", payload, { headers: { Authorization: '' } })
      .then((result) => {
        _.userTypeList = result.body.data

        if (_.userTypeList && _.userTypeList.length > 0) _.authType = _.userTypeList[0].authType
        
        _.loaderAuthList = false
        
      })
      .catch((err) => {
        _.loaderAuthList = false
        _.Notify(_.$i18n.t("errorLoadingAuthTypeList"), 1, true);
        console.error(err);
      });
  },

  data() {
    return {
      // eslint-disable-next-line no-undef
      authType: {},
      authUsername: "",
      authPassword: "",
      // eslint-disable-next-line no-undef
      userTypeList: [],
      loading: false,
      loaderAuthList: false
    };
  },

  methods: {
    checkLogin() {
      let _ = this;
      if (_.authUsername.length === 0) {
        _.Notify(_.$i18n.t("missingUser"), 1, true);
        return;
      }
      if (_.authPassword.length === 0) {
        _.Notify(_.$i18n.t("missingPassword"), 1, true);
        return;
      }
      _.loading = true;

      var payload = {
        username: _.authUsername.toUpperCase(),
        password: _.authPassword,
        userType: _.authType,
      };
      _.$http
        // eslint-disable-next-line no-undef
        .post(config.cmUrl + "/auth/login", payload)
        .then((result) => {
          localStorage.setItem("AWS_USER", _.authUsername);
          // eslint-disable-next-line no-undef
          config.AWS_USER = _.authUsername;
          for (const token of result.body.data) {
            localStorage.setItem(token.name, token.value);
            // eslint-disable-next-line no-undef
            config[token.name] = token.value;
          }
          // eslint-disable-next-line no-undef
          config.authSuccessful = true;
          _.loading = false;
          _.$router.push({ name: 'Home' }).catch(() => { })
        })
        .catch((err) => {
          _.loading = false;
          _.Notify(_.$i18n.t("loginFailed"), 1, true);
          console.error(err);
        })
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/style.less";
</style>
