/* eslint-disable no-undef */
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import Notifications from 'vue-notification'
import VueResource from 'vue-resource'
import VueI18n from 'vue-i18n'
//import messages from '../locale/trad.js'

import router from './router'

// Setup global variables
//config.userLang = sessionStorage.getItem('pas_lang') ? sessionStorage.getItem('pas_lang') : 'it_IT';

config.AWS_APP_TOKEN = localStorage.getItem('AWS_APP_TOKEN');
config.AWS_USER = localStorage.getItem('AWS_USER');
config.SERVICE_TOKEN = localStorage.getItem('SERVICE_TOKEN');

Vue.use(Notifications)

Vue.use(VueResource)
Vue.use(VueI18n)

if (location.hostname !== 'localhost')
  config.cmUrl = process.env.VUE_APP_BASE_URL

const all = require('../locale/all.json')

//console.log('LOADING LANG', config.userLang)

const i18n = new VueI18n({
  locale: config.userLang ? config.userLang :'en_GB',
  fallbackLocale: config.userLang ? config.userLang :'en_GB',
  messages: all
})

// HTTP interceptors
Vue.http.interceptors.push((request, next) => {
  //let _ = this

  config.AWS_APP_TOKEN = localStorage.getItem('AWS_APP_TOKEN');
  config.AWS_USER = localStorage.getItem('AWS_USER');
  config.SERVICE_TOKEN = localStorage.getItem('SERVICE_TOKEN');
  config.authSuccessful = config.AWS_APP_TOKEN && config.AWS_APP_TOKEN !== ''
  if (config.cmUrl && config.cmUrl.length > 0 && request.url.includes(config.cmUrl)) { // OK
    request.headers.set('Authorization', 'Bearer ' + config.AWS_APP_TOKEN)
  }

  if (config.serviceUrl && config.serviceUrl.length > 0 && request.url.includes(config.serviceUrl)) { // OK
    request.headers.set('Token', config.SERVICE_TOKEN)
  }

  next(function (response) {
    if ([0, 401, 403].includes(response.status)) {
      config.authSuccessful = false
      /*notify({
        group: 'main',
        text: i18n.t("invalidToken"),
        type: 'error',
      });*/

      router.push({ name: 'Login' }).catch(() => { });
    }
  })
})

new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')

router.push({ name: 'Dashboard' }).catch(()=>{});