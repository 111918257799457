import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = { //#00487c blu iframework
    primary: '#009C3C',
    secondary: '#4caf50',
    accent: '#D1DF8F',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    error: '#F44336',
}

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            dark: theme,
            light: theme,
        },
    }
});
