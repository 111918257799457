<template>
  <v-row class="pa-3 mb-0 pb-0 pr-5" style="height:100%; align-content: flex-start" fill-height>
    <v-col cols="8" class="text-uppercase">
      <span class="text-uppercase primary--text text-h5">
        {{ selectedContainer && selectedContainer.descrizione ? selectedContainer.descrizione : $t('archivio-documentale')
        }}
      </span>
    </v-col>
    <v-col cols="4" class="d-flex justify-end">
      <v-tooltip bottom v-if="selectedContainer.action &&
        selectedContainer.action.includes('FOLDER') &&
        tableItemsCount > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="exportModalShow = true">
            <v-icon size="24">
              mdi-export-variant
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('esportazione-massiva') }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="selectedContainer && selectedContainer._id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="newSaveSearch()">
            <v-icon size="24">
              mdi-content-save
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('salva-ricerca') }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="
        selectedContainer.action &&
        selectedContainer.action.includes('PDF') &&
        tableItemsCount > 0
      ">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="
            newElaboration({
              type: 'PDF',
              desc: 'Crea PDF Unico',
            })
          ">
            <v-icon size="24" class="ml-1">
              mdi-format-page-break
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('crea-pdf-unico') }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="
        selectedContainer.action &&
        selectedContainer.action.includes('ZIP') &&
        tableItemsCount > 0
      ">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="
            newElaboration({
              type: 'ZIP',
              desc: 'Crea file ZIP',
            })
          ">
            <v-icon size="24" class="ml-1">
              mdi-zip-box
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('crea-file-zip') }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="tableItemsSelected.length > 0 && tableItemsSelected.length <= 20">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="downloadSelection()">
            <v-icon size="24" class="ml-1">
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('scarica-file-selezionati') }}</span>
      </v-tooltip>
    </v-col>

    <v-col class="pa-0 ma-0 pl-1 pr-1 filtersBar" v-if="!filterMenuShow"
      @click="
        filterMenuShow = true;
      rowItemMenuShow = false;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ">
      <v-icon size="24" color="primary"> mdi-chevron-right </v-icon>

      <span class="mt-5 font-weight-bold">{{ $t('filtri') }}</span>
    </v-col>

    <!-- FILTERS MENU -->
    <v-col cols="3" class="pl-6" v-if="filterMenuShow">
      <v-row>
        <v-autocomplete @change="initFilters()" :placeholder="$t('contenitore')" :items="containers"
          v-model="selectedContainer" item-text="nome" return-object>

          <template v-slot:item="{ item, }">
            {{ item.desc[config.userLang] }}
          </template>

        </v-autocomplete>

        <v-icon color="primary" size="40" @click="filterMenuShow = false">
          mdi-chevron-left
        </v-icon>
      </v-row>

      <v-row style="overflow-y: auto; height: 70vh" fill-height class="align-content-start">
        <v-container class="pa-0 pl-1 pr-1 ma-0" v-for="(f, k) in selectedContainer.filtri" :key="k"
          style="align-content: flex-start">
          <!-- DATE filter -->
          <v-row no-gutters v-if="f.type === 'date'">
            <v-col cols="4">
              {{ f.trad[config.userLang] }} {{ f.mandatory ? "*" : "" }}
            </v-col>
            <v-col>
              <div class="d-flex flex-row align-center">
                <v-menu v-model="datePickersShow[k]" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="ml-4" dense :value="datePickerFormatter(f, k)" clearable
                      :placeholder="$t('valore')" readonly v-bind="attrs" v-on="on" @click:clear="
                        filtersValues[f.name].value = filtersValues[
                          f.name
                        ].rawDate = null
                      "></v-text-field>
                  </template>
                  <v-date-picker v-model="filtersValues[f.name].rawDate"
                    @change="datePickersShow[k] = false"></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>

          <!-- SELECT filter -->
          <v-row no-gutters v-else-if="f.type === 'select'">
            <v-col cols="4">
              {{ f.trad[config.userLang] }} {{ f.mandatory ? "*" : "" }}
            </v-col>
            <v-col>
              <div class="d-flex flex-row align-center">
                <v-autocomplete class="ml-5" dense :placeholder="$t('valore')" v-model="filtersValues[f.name].value"
                  :loading="loadingSelect[k]" @click="loadSelect(f, k)" :items="selectItems[f.name]"
                  item-text="descrizione" item-value="codice" @change="changeSelect(f, k)" clearable />
              </div>
            </v-col>
          </v-row>

          <!-- TEXT filter -->
          <v-row no-gutters v-else-if="f.type === 'string' || f.type === 'text'">
            <v-col cols="4">
              {{ f.trad[config.userLang] }} {{ f.mandatory ? "*" : "" }}
            </v-col>
            <v-col>
              <div class="d-flex flex-row align-center">
                <v-select style="max-width: 100px" class="ml-5" dense :placeholder="$t('valore')"
                  :items="filterMethodsStrings" item-text="nome" item-value="codice"
                  v-model="filtersValues[f.name].operation" clearable />

                <v-text-field class="ml-4" dense type="text" :placeholder="$t('valore')"
                  v-model="filtersValues[f.name].value" @change="changeText(f, k)" clearable>
                </v-text-field>
              </div>
            </v-col>
          </v-row>

          <!-- NUMBER filter -->
          <v-row no-gutters v-else>
            <v-col cols="4">
              {{ f.trad[config.userLang] }} {{ f.mandatory ? "*" : "" }}
            </v-col>
            <v-col>
              <div class="d-flex flex-row align-center">
                <v-select style="max-width: 100px" class="ml-5" dense :placeholder="$t('valore')"
                  :items="filterMethodsNumbers" item-text="nome" item-value="codice"
                  v-model="filtersValues[f.name].operation" clearable />

                <v-text-field class="ml-4" dense type="number" :placeholder="$t('valore')"
                  v-model="filtersValues[f.name].value" clearable @change="changeText(f, k)">
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mt-3 mr-5" @click="initFilters()" outlined>{{ $t('svuota-campi') }}</v-btn>
        <v-btn color="primary" class="mt-3" :disabled="!mandatoriesOk" @click="search()">{{ $t('cerca') }}</v-btn>
      </v-row>
    </v-col>

    <!-- TABLE WITH SEARCH ITEMS -->
    <v-col class="pr-7">
      <v-row no-gutters>
        <v-col cols="2" class="text-left">{{ $t('total-items-count', [tableItemsCount]) }}</v-col>
        <v-col cols="8" class="text-center">
          {{ selectedContainer && selectedContainer.descrizione ? selectedContainer.descrizione : "" }}
          {{ now() }}
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-data-table show-select height="71vh" fixed-header dense :headers="tableHeaders" :items="tableItems"
        :items-per-page="20" hide-default-footer class="elevation-2 mt-3" :page.sync="tablePage" :loading="tableLoading"
        item-key="_id" v-model="tableItemsSelected" @click:row="itemInfo" return-object :options.sync="tableOptions">
      </v-data-table>

      <v-pagination class="mt-5" prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="secondary"
        v-model="tablePage" :length="Math.ceil(tableItemsCount / 20)" total-visible="12" @input="search()">
      </v-pagination>
    </v-col>

    <!-- ITEM META INFOS -->
    <v-col class="ma-0 pa-0 pr-2 align-content-start" v-if="rowItemMenuShow" style="max-width: 360px">
      <v-icon color="primary" size="40" @click="rowItemMenuShow = false">
        mdi-chevron-right
      </v-icon>

      <v-container no-gutters class="pa-0 ma-0 justify-center"
        style="overflow-y: auto; height: 72vh; align-content: flex-start" fill-height>

        <img class="mt-3 mb-5" :src="rowItemMenuPreview" @click="downloadFile(rowSelected)" width="70%"
          style="cursor:pointer" />

        <v-expansion-panels accordion>
          {{ $t('dimensione-row', [rowSelected.kbSize]) }}
          <v-progress-linear indeterminate :key="rowItemLoadingKey" v-show="rowItemLoading"
            color="primary"></v-progress-linear>

          <v-expansion-panel class="mt-4">
            <v-expansion-panel-header>{{
              $t("metadata")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters class="pa-0 ma-0 text-caption align-center"
                v-for="(attr, k) in fileMetasNoS3(selectedContainer.attributes)" :key="k">
                <v-col cols="5">
                  {{ attr.trad[config.userLang] }}
                </v-col>
                <v-col>
                  <v-text-field dense type="text" v-model="rowSelected[attr.name]" disabled />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("system")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="(meta, k) in config.fileMetas" :key="-1 + k * -1" no-gutters
                class="pa-0 ma-0 text-caption align-center">
                <v-col cols="5">
                  {{ meta }}
                </v-col>
                <v-col>
                  <v-text-field dense type="text" v-model="rowSelected[meta]" disabled />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>

    <!-- DIALOG NEW ELAB -->
    <v-dialog v-model="actionModalShow" v-if="actionModalShow" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('nuova-elaborazione') }}: {{ actionModalType.desc }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field dense :label="$t('descrizione')" class="mb-3" v-model="actionModalData.desc" />
            <v-text-field dense :label="$t('password')" v-model="actionModalData.password" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="actionModalShow = false">
            {{ $t('annulla') }} </v-btn>
          <v-btn color="primary" text @click="saveElaboration()"> {{ $t('salva') }} </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- DIALOG SAVE SEARCH-->
    <v-dialog v-model="saveSearchModalShow" v-if="saveSearchModalShow" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('modifica-descrizione') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field dense :label="$t('descrizione')" class="mb-3" v-model="saveSearchDescription" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveSearchModalShow = false">
            {{ $t('annulla') }} </v-btn>
          <v-btn color="primary" text @click="saveSearch()"> {{ $t('salva') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG EXPORT -->
    <v-dialog v-model="exportModalShow" v-if="exportModalShow" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('esportazione-massiva') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-select :items="exportModalTypes" :label="$t('tipo-esportazione')" v-model="exportModalData.type"
              @change="resetExportModal()"></v-select>

            <v-text-field :label="$t('descrizione-elaborazione')" v-model="exportModalData.desc"></v-text-field>

            <!-- Export AWS S3 -->
            <v-container class="pa-0 ma-0" v-if="exportModalData.type && exportModalData.type === 'S3'">
              <v-text-field :label="$t('nome-bucket')" v-model="exportModalData.bucketName"></v-text-field>
            </v-container>

            <!-- Export (S)FTP -->
            <v-container class="pa-0 ma-0"
              v-else-if="exportModalData.type && (exportModalData.type === 'FTP' || exportModalData.type === 'SFTP')">
              <v-text-field :label="$t('indirizzo-server')" v-model="exportModalData.host"></v-text-field>
              <v-text-field :label="$t('porta')" v-model="exportModalData.port"></v-text-field>
              <v-text-field :label="$t('username')" v-model="exportModalData.username"></v-text-field>
              <v-text-field :label="$t('password')" type="password" v-model="exportModalData.password"></v-text-field>
              <v-text-field :label="$t('percorso-remoto')" v-model="exportModalData.dstPath"></v-text-field>
            </v-container>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="exportModalShow = false">
            {{ $t('annulla') }} </v-btn>
          <v-btn color="primary" text @click="massiveExport()"> {{ $t('invia') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import mixins from "./mixins.js";
import moment from "moment";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Tabella',

  props: {
    iContainer: Object, // IF NOT NULL favorite selected
    iContainers: Array, // All user's containers
    iFilter: Object, // IF NOT NULL search selected
  },

  mixins: [mixins],

  data() {
    return {
      config: null,
      containers: [], // List of all authorized containers

      selectedContainer: {}, // Current selected container (also by favorites)
      selectedFilter: {}, // Load saved filter

      filtersValues: {}, // User inputs for each filter
      filterMenuShow: true, // Show left menu - filters

      exportModalShow: false, // Export to external destination modal toggle
      exportModalData: {}, // Export to external destination modal data
      exportModalTypes: [
        {
          text: this.$t('bucket-s3'),
          value: 'S3'
        },
        {
          text: this.$t('server-ftp'),
          value: 'FTP'
        },
        {
          text: this.$t('server-sftp'),
          value: 'SFTP'
        },
      ],

      selectItems: {}, // Service-loaded select inputs
      loadingSelect: [], // Flags for service-loaded select inputs loading animation
      datePickersShow: [], // Flags for opened date pickers

      mandatoriesOk: false, // Passed test for filled mandatory filters values

      tableHeaders: [], // Table headers

      tableItems: [], // Table items in page (20 items for each api response)
      tableItemsSelected: [], // Array for table selected rows

      rowSelected: {}, // Clicked row for meta show
      rowItemMenuShow: false, // Show right menu - metas
      rowItemMenuPreview: "", // Path to preview image
      rowItemLoading: false, // download icon loading
      rowItemLoadingKey: 0,
      tablePage: 1, // Pagination value
      tableItemsCount: 0, // Number of items satisfying the filters
      tableLoading: false, // Table loader
      tableOptions: {}, // Table options (sorting)

      actionModalShow: false, // Show modal - elab submit
      actionModalType: {}, // Modal elab type
      actionModalData: {}, // Modal elab user inputs

      saveSearchModalShow: false, // Show modal - save search
      saveSearchDescription: "", // Modal save search user input

      filterMethods: [
        {
          nome: this.$t("operazioni_like"),
          codice: "LIKE",
        },
        {
          nome: this.$t("operazioni_neq"),
          codice: "NEQ",
        },
        {
          nome: this.$t("operazioni_eq"),
          codice: "EQ",
        },
        {
          nome: this.$t("operazioni_less"),
          codice: "LT",
        },
        {
          nome: this.$t("operazioni_lte"),
          codice: "LTE",
        },
        {
          nome: this.$t("operazioni_greater"),
          codice: "GT",
        },
        {
          nome: this.$t("operazioni_gte"),
          codice: "GTE",
        },
      ],

      filterMethodsStrings: [
        {
          nome: this.$t("operazioni_like"),
          codice: "LIKE",
        },
        {
          nome: this.$t("operazioni_eq"),
          codice: "EQ",
        },
        {
          nome: this.$t("operazioni_neq"),
          codice: "NEQ",
        },
        // concat keydoc_op
      ],

      filterMethodsNumbers: [
        {
          nome: this.$t("operazioni_eq"),
          codice: "EQ",
        },
        {
          nome: this.$t("operazioni_less"),
          codice: "LT",
        },
        {
          nome: this.$t("operazioni_lte"),
          codice: "LTE",
        },
        {
          nome: this.$t("operazioni_greater"),
          codice: "GT",
        },
        {
          nome: this.$t("operazioni_gte"),
          codice: "GTE",
        },
        // concat keydoc_op
      ],
    };
  },
  created() {
    // eslint-disable-next-line no-undef
    this.config = config;
  },

  watch: {
    tableOptions: {
      handler() {
        let _ = this

        _.search()
      },

      deep: true,
    },
  },

  methods: {
    fileMetasNoS3(attributes) {
      let _ = this

      //selectedContainer.attributes
      //console.log(attributes)

      return attributes.filter(x => /*x.showTable === 'S' &&*/ !_.config.fileMetas.includes(x.name))

    },
    resetExportModal() {

      let _ = this
      let type = _.exportModalData.type
      _.exportModalData = { type: type }

    },
    massiveExport() {
      let _ = this;

      _.exportModalShow = false

      //console.log('Called massive export', this.exportModalData)

      let filtersCleanValues = _.getFiltersCleanValues();

      let exportConfig = { ..._.exportModalData }
      _.exportModalData = {}

      let payload = {
        contenitore: _.selectedContainer.nome,
        filtri: filtersCleanValues,

        descrizione: exportConfig.desc,

        tipo: {
          codiceTipo: 'FOLDER',
          descrizioneTipo: 'FOLDER',
        },

        numRisultati: _.tableItemsCount,
        exportConfig: {}
      };

      if (exportConfig.type === 'S3') {
        payload.exportConfig.bucketTo = exportConfig.bucketName
      } else {
        payload.exportConfig = {
          host: exportConfig.host,
          port: exportConfig.port,
          username: exportConfig.username,
          password: exportConfig.password,
        }

        payload.exportConfig.dstPath = exportConfig.dstPath
      }

      payload.exportConfig.connectionType = exportConfig.type

      _.$http
        .post(this.config.cmUrl + "/user/prefs/insert/elab", payload)
        .then(() => {
          _.Notify(_.$i18n.t("elab_successADD"), 0);

        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {

            _.Notify(_.$i18n.t("elab_failedADD"), 1);
            console.error(err);
          }
        });
    },
    now() {
      let now = new Date().toJSON();
      now = now.split(".")[0];
      now = now.replace("T", " ");

      return now;
    },

    getKBytes(bytes) {
      let round = bytes % 1000;
      bytes = bytes / 1000;

      if (round > 500) return Math.ceil(bytes);
      else return Math.floor(bytes);
    },

    downloadFile(item) {
      // Download single file by given item
      let _ = this;

      if (_.rowItemLoading) // Block download while already downloading this file
        return

      // _.$set(_.rowItemLoading, item.keydoc, true);
       _.rowItemLoading = true
      _.rowItemLoadingKey++
      _.$http
        .post(
          _.config.cmUrl +
          "/docs/getDocumentUrl/" +
          _.selectedContainer.nome +
          "/" +
          item.keydoc
        )
        .then((result) => {
          let newWin = window.open(result.body.data.url);
          newWin.document.title = item.keydoc;
          
          // _.$set(_.rowItemLoading, item.keydoc, false);
          _.rowItemLoading = false
          _.rowItemLoadingKey++
        })
        .catch((err) => {
          if ([0, 401, 403].includes(err.status)) {
            // _.$set(_.rowItemLoading, item.keydoc, false);
            _.rowItemLoading = false
            _.rowItemLoadingKey++
            _.Notify(_.$i18n.t("failimg"))
            console.error(err);
          }
        });
    },

    downloadSelection() {
      // Download all files selected in table
      let _ = this;

      // eslint-disable-next-line no-undef
      let zip = new JSZip();

      let fileCounter = 0;

      _.tableLoading = true;

      _.tableItemsSelected.forEach(function (item) {
        _.$http
          .post(
            _.config.cmUrl +
            "/docs/getDocument/" +
            _.selectedContainer.nome +
            "/" +
            item.keydoc
          )
          .then((result) => {
            let body = result.body;

            fileCounter++;

            let docType = body.data.contentType.split("/");

            zip.file(
              _.selectedContainer.nome + "_" + item.keydoc + "." + docType[1],
              body.data.file,
              { base64: true }
            );

            if (_.tableItemsSelected.length === fileCounter) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                // eslint-disable-next-line no-undef
                saveAs(content, _.selectedContainer.nome + ".zip");

                _.tableLoading = false;
                _.tableItemsSelected = [];
              });
            }
          })
          .catch((err) => {
            if ([0, 401, 403].includes(err.status)) {
              _.tableLoading = false;

              _.Notify(this.$t('download-multiplo-fallito'))
              console.error(err);
            }
          });
      });
    },

    newSaveSearch() {
      // Dialog new search saving
      let _ = this;

      let filtersCleanValues = _.getFiltersCleanValues();

      if (filtersCleanValues.length === 0) {
        _.Notify(this.$t('compila-per-salvare'), 1);
        return;
      }

      _.saveSearchDescription = _.selectedContainer.nome;
      _.saveSearchModalShow = true;
    },

    getFiltersCleanValues() {
      // Clean out filters fields (for API calls)
      let _ = this;

      let filtersCleanValues = Object.values({ ..._.filtersValues }).filter(
        (f) => f.value
      ); // Filter by not null values

      for (const filter of filtersCleanValues) {
        // Delete null-valued proprieties
        Object.keys(filter).forEach(
          (k) => filter[k] === null && delete filter[k]
        );
      }

      return filtersCleanValues;
    },

    saveSearch() {
      // Save search
      let _ = this;

      let filtersCleanValues = _.getFiltersCleanValues();

      let payload = {
        contenitore: _.selectedContainer.nome,
        descrizione: _.selectedContainer.desc[this.config.userLang],
        filtri: filtersCleanValues,
        nomeRicerca: _.saveSearchDescription,
      };

      if (_.saveSearchDescription && _.saveSearchDescription.length > 0) {
        // Check description
        _.$http
          .post(this.config.cmUrl + "/user/prefs/insert/searches", payload)
          .then(() => {
            _.Notify(_.$i18n.t("elab_successADD"))
            _.saveSearchModalShow = false;
          })
          .catch((err) => {
            if ([0, 401, 403].includes(err.status)) {
              _.saveSearchModalShow = false;

              _.Notify(_.$i18n.t("elab_failedADD"))
              console.error(err);
            }
          });
      } else {
        _.Notify(this.$t('descrizione-obbligatoria'), 1);
      }
    },

    newElaboration(type) {
      // Dialog new elaboration
      let _ = this;

      _.actionModalData = {
        desc: "",
        password: "",
      };

      _.actionModalType = type;
      _.actionModalShow = true;
    },

    saveElaboration() {
      // Save new elaboration
      let _ = this;

      let params = _.actionModalData;
      let elabType = _.actionModalType;

      let filtersCleanValues = _.getFiltersCleanValues();

      let payload = {
        descrizione: params.desc,
        password: params.password,

        contenitore: _.selectedContainer.nome,
        filtri: filtersCleanValues,

        tipo: {
          codiceTipo: elabType.type,
          descrizioneTipo: elabType.desc,
        },

        numRisultati: _.tableItemsCount,
      };

      if (params.password && params.password.length > 0) {
        // Check password
        _.$http
          .post(this.config.cmUrl + "/user/prefs/insert/elab", payload)
          .then(() => {
            _.Notify(_.$i18n.t("message.elab.successADD"), 0);

            _.actionModalShow = false;
          })
          .catch((err) => {
            if ([0, 401, 403].includes(err.status)) {
              _.actionModalShow = false;

              _.Notify(_.$i18n.t("elab_failedADD"), 1);
              console.error(err);
            }
          });
      } else {
        _.Notify(this.$t('password-obbligatoria'), 1);
      }
    },

    itemInfo(item, row) {
      // Show info menu for given item
      let _ = this;
      if (!_.rowItemLoading) {
        _.filterMenuShow = false; // Hide filter menu

        if (item.S3mimeType && item.S3mimeType.includes("pdf"))
          _.rowItemMenuPreview = "/static/img/preview_PDF.jpg";
        else if (item.S3mimeType && item.S3mimeType.includes("image"))
          _.rowItemMenuPreview = "/static/img/preview_IMG.jpg";
        else _.rowItemMenuPreview = "/static/img/preview_GEN.png";

        _.rowSelected = _.tableItems[row.index];
        _.$set(_.rowSelected, "kbSize", _.getKBytes(_.rowSelected.S3size));
        _.rowItemMenuShow = true;
      } else {
        console.dir('Attendere termine download doc precedente')
      }
    },

    initFilters() {
      // Clean out filters, loaders and fields
      let _ = this;
      _.filtersValues = [];
      _.loadingSelect = [];
      _.tableHeaders = [];
      _.tableItems = [];
      _.tableItemsCount = 0;
      for (const filter of _.selectedContainer.filtri) {
        _.$set(_.filtersValues, filter.name, {
          // Generate default items for filters
          name: filter.name,
          value: null,
          operation: null,
          rawDate: null,
        });
      }
      _.checkMandatory();
    },

    changeSelect(filter) {
      // Handle select value change
      let _ = this;

      const nullField = {
        name: filter.name,
        value: null,
        operation: null,
        rawDate: null,
      };

      // Pick filters names that depend from edited filter
      let dependentFilters = [
        ..._.selectedContainer.filtri
          .filter((f) => f.dipendenze && f.dipendenze.includes(filter.name))
          .map((f) => f.name),
      ];

      // Filter all filled fields
      let filledFields = [
        ...Object.keys(_.filtersValues).filter((f) => _.filtersValues[f].value),
        filter.name,
      ];

      // For every filled filter value
      for (const filledField of filledFields) {
        if (dependentFilters.includes(filledField)) {
          // If filled filter depends from changed filter
          _.$set(_.filtersValues, filledField, { ...nullField }); // Reset value (v-model)
          _.$set(_.selectItems, filledField, []); // Reset items (:items)
        }
      }

      _.checkMandatory();
    },

    changeText(filter /*, index*/) {
      // Handle text field change
      let _ = this;

      let newVal = _.filtersValues[filter.name];

      if (!_.filtersValues[filter.name].operation) {
        // If no operation is selected, set to EQ
        newVal.operation = "EQ";
        _.$set(_.filtersValues, filter.name, newVal); // Reset items (:items)
      }

      let pattern = _.selectedContainer.filtri.find(
        (f) => f.name === newVal.name
      ).pattern;

      if (pattern) {
        let regex = new RegExp(pattern);

        if (regex.test(_.filtersValues[filter.name].value)) _.checkMandatory();
        else {
          _.Notify(_.t('controllo-pattern-fallito'), 1);
          _.mandatoriesOk = false;
        }
      }
    },

    changeDate(filter /*index*/) {
      // Handle date field change
      let _ = this;

      let newVal = _.filtersValues[filter.name];
      let pattern = _.selectedContainer.filtri.find(
        (f) => f.name === newVal.name
      ).pattern;

      if (pattern) {
        pattern = pattern.toUpperCase(); // TOFIX - Date configuration error
        newVal.value = moment(newVal.rawDate).format(pattern);
      } else {
        newVal.value = newVal.rawDate;
      }

      _.$set(_.filtersValues, filter.name, newVal);

      if (!_.filtersValues[filter.name].operation) {
        // If no operation is selected, set to EQ
        let newVal = _.filtersValues[filter.name];
        newVal.operation = "EQ";

        _.$set(_.filtersValues, filter.name, newVal); // Reset items (:items)
      }

      _.checkMandatory();
    },

    datePickerFormatter(filter /*index*/) {
      // Format model date picker (rawDate) to value

      let _ = this;

      let newVal = _.filtersValues[filter.name];
      let pattern = _.selectedContainer.filtri.find(
        (f) => f.name === newVal.name
      ).pattern;

      if (!newVal.rawDate) return null;

      _.changeDate(filter);

      if (pattern) {
        pattern = pattern.toUpperCase(); // TOFIX - Date configuration error
        return moment(newVal.rawDate).format(pattern);
      } else {
        return newVal.rawDate;
      }
    },

    loadSelect(filter, index) {
      // Load select by calling its service
      let _ = this;

      // Check if select already have data stored
      if (_.selectItems[filter.name] && _.selectItems[filter.name].length > 0)
        return;

      let evalServiceUrl = filter.service.url.toString();

      if (filter.dipendenze.length > 0) {
        // Filter has dependencies
        let dip = filter.dipendenze;

        let filledFields = Object.keys(_.filtersValues).filter(
          (f) => _.filtersValues[f].value
        );
        let dependenciesCheck = filledFields
          ? dip.every((d) => filledFields.includes(d))
          : false;

        if (!dependenciesCheck) {
          _.Notify(this.$t('controllo-dipendenze-fallito'), 1);
          console.dir("Controllo dipendenze fallito");
          return;
        }
      }

      if (filter.parametri.length > 0) {
        // Filter has parameters
        let params = filter.parametri;

        for (const param of params) {
          evalServiceUrl = evalServiceUrl.replace(
            `{${param}}`,
            _.filtersValues[param].value
          );
        }
      }

      if (filter.service) {
        // Filter has a service
        let serv = filter.service;
        _.$set(_.loadingSelect, index, true);

        switch (serv.tipo) {
          case "GET":
            _.$http
              .get(this.config.serviceUrl + evalServiceUrl)
              .then((result) => {
                _.$set(_.loadingSelect, index, false);
                _.$set(_.selectItems, filter.name, result.data.data);

                _.checkMandatory();
              })
              .catch((err) => {
                if ([0, 401, 403].includes(err.status)) {
                  _.$set(_.loadingSelect, index, false);

                  _.Notify(this.$t('errore-load-select'), 1);
                  console.error(err);
                }
              });
            break;

          case "POST":
            _.$http
              .post(this.config.serviceUrl + evalServiceUrl, {})
              .then((result) => {
                _.$set(_.loadingSelect, index, false);
                _.$set(_.selectItems, filter.name, result.data.data);

                _.checkMandatory();
              })
              .catch((err) => {
                if ([0, 401, 403].includes(err.status)) {
                  _.$set(_.loadingSelect, index, false);

                  _.Notify(this.$t('errore-load-select'), 1);
                  console.error(err);
                }
              });
            break;
        }
      }
    },

    checkMandatory() {
      // Check mandatory fields
      let _ = this;

      _.mandatoriesOk =
        _.selectedContainer.filtri
          .filter((f) => f.mandatory) // Only mandatory fields
          .every((f) => _.filtersValues[f.name].value) && // Every field needs a value
        _.loadingSelect.reduce((x, y) => x + y, 0) === 0; // Check for 0 loading select
    },

    search() {
      // Handle search
      let _ = this;

      if (!_.mandatoriesOk) return;

      _.tableLoading = true;

      // Generate table headers with Vuetify object format
      _.tableHeaders = _.selectedContainer.attributes
        .filter((a) => a.showTable && a.showTable === "S")
        .map((a) => {
          return {
            value: a.name,
            text: a.trad[this.config.userLang],
          };
        });

      let data = Object.values(_.filtersValues).filter((v) => v.value);
      let sort = null

      if (_.tableOptions.sortBy && _.tableOptions.sortBy.length > 0) { // Handle table sorting
        sort = {}

        sort.col = _.tableOptions.sortBy[0]
        sort.order = _.tableOptions.sortDesc[0] ? 'descending' : 'ascending'
      }

      // console.log('Sort obj', sort)

      let body = {
        data: data,
        page: _.tablePage,
      }

      if (sort)
        body.sort = sort

      _.$http
        .post(this.config.cmUrl + "/docs/count/" + _.selectedContainer._id, body)
        .then((result) => {
          _.tableItemsCount = result.data.data.total;
        })
        .catch((err) => {
          if ([0, 401, 403, 500].includes(err.status)) {
            _.tableLoading = false;

            _.Notify(this.$t('errore-load-count'), 1);
            console.error(err);
          }
        });

      _.$http
        .post(this.config.cmUrl + "/docs/search/" + _.selectedContainer._id, body)
        .then((result) => {
          _.tableItems = result.data.data;

          _.tableLoading = false;
        })
        .catch((err) => {
          if ([0, 401, 403, 500].includes(err.status)) {
            _.tableLoading = false;

            _.Notify(this.$t('errore-load-docs'), 1);
            console.error(err);
          }
        });
    },
  },

  mounted: function () {
    let _ = this;

    _.containers = Array.from(_.iContainers);

    if (_.iContainer) {
      // Load container from props
      _.selectedContainer = { ..._.iContainer };

      _.selectedContainer = _.containers.find(
        (c) => c.nome == _.selectedContainer.nome
      );

      //console.log("Favorite IN", _.selectedContainer);

      _.initFilters();
      _.$emit("resetAll");
    }

    if (_.iFilter) {
      // Load filter from props
      _.selectedFilter = { ..._.iFilter };

      //console.log("Filter IN", _.selectedFilter);

      _.selectedContainer = _.containers.find(
        (c) => c.nome == _.selectedFilter.nome
      );

      //console.log("Calculated container", _.selectedContainer);

      _.initFilters();

      for (const filter of _.selectedFilter.filtri) {
        // Find field to be filled
        let filterField = _.selectedContainer.filtri.find(
          (f) => f.name === filter.name
        );
        let filterFieldIndex = _.selectedContainer.filtri.indexOf(filterField);

        // Init load select
        if (filterField.type === "select") {
          _.loadSelect(filterField, filterFieldIndex);
        }

        let value = filter.value ? filter.value : null;
        let rawDate = null;
        let dateFormat = null;

        if (filterField.type === "date") {
          let pattern = filterField.pattern;
          dateFormat = filterField.format_input;

          value = moment(filter.value, pattern).format(dateFormat);

          //console.log("pattern:", pattern);
          //console.log("dateFormat:", dateFormat);

          rawDate = moment(filter.value, pattern).format(
            dateFormat.toUpperCase()
          ); // Format date as HTML input format & TOFIX - Date configuration error
        }

        // Fill filter field proprieties
        _.$set(_.filtersValues, filter.name, {
          name: filter.name,
          value: value,
          operation: filter.operation,
          rawDate: rawDate,
        });
      }

      _.checkMandatory();
      _.$emit("resetAll");
    }
  },
};
</script>

<style scoped>
.filtersBar {
  max-width: 30px;
  background-color: #e7f3fa;
  cursor: pointer;
}

.filtersBar span {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: var(--v-primary-base);
}
</style>