import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Dashboard from '@/components/Dashboard'
import Tabella from '@/components/Tabella'
import Login from '@/components/Login'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      children: [
        { path: '/Dashboard', name: 'Dashboard', component: Dashboard },
        { path: '/Tabella', name: 'Tabella', component: Tabella }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    }
  ]
})
