<template>
    <v-container fill-height class="secondary pb-3 pl-3" style="align-content:flex-start">
        <v-btn fab @click="goToDashboard()">
            <v-icon x-large :color="$route.name === 'Dashboard' ? 'primary' : 'grey'">mdi-home</v-icon>
        </v-btn>
        <v-btn fab @click="goToTabella()" class="mt-3">
            <v-icon x-large :color="$route.name === 'Tabella' ? 'primary' : 'grey'">mdi-tune-vertical-variant</v-icon>
        </v-btn>
    </v-container>
</template>


<script>
export default {
    name: 'NavLeft',

    methods: {
        goToDashboard() {
            this.$emit('goTo', 'Dashboard');
        },

        goToTabella() {
            this.$emit('goTo', 'Tabella');
        }
    }
}
</script>
