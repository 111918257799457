<template>
  <v-app-bar color="white" elevation="3" class="pa-1">
    <img src="/static/img/logo.png" style="height: 64px; width: 64px" />
    <h1 class="ml-5 mt-1 text-uppercase success--text font-weight-regular">{{ title }}</h1>

    <v-spacer />

    <span class="text-body-1 text-uppercase">{{ user }}</span>
    <v-icon class="ml-1">mdi-account</v-icon>
    <v-icon class="ml-4" @click="logout()">mdi-logout</v-icon>
  </v-app-bar>
</template>

<script>
//import config from "../../public/static/js/config.js";
export default {
  name: "HeaderApp",

  data() {
    return {
      title: this.$t('content-manager'),
      // eslint-disable-next-line no-undef
      user: config.AWS_USER,
    };
  },

  methods: {
    logout() {
      let _ = this;

      localStorage.removeItem("USER");
      // eslint-disable-next-line no-undef
      config.AWS_USER = "";

      for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);

        if (key.includes("_TOKEN")) {
          localStorage.removeItem(key);
          // eslint-disable-next-line no-undef
          config[key] = "";
        }
      }
      // eslint-disable-next-line no-undef
      config.AWS_USER = config.AWS_APP_TOKEN = config.SERVICE_TOKEN = "";
      _.$router.push({ name: 'Login' }).catch((err) => err);
    },
  },
};
</script>